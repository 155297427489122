.panel-one{
  background-color: rgb(29, 71, 58);
  
  width: 25%;
  max-width: 256px;
  
  padding-left: 16px;
  padding-right: 16px;
  color: white;
}


.panel-content{
  width: calc(75% - 2px);
}

body {
  margin: 0px;
  -webkit-print-color-adjust:exact !important;
  print-color-adjust:exact !important;

}
.feed {
  border: 1px solid rgb(29, 71, 58);
  max-width: 1280px;
  min-height: 100vh;

  display: -ms-flex; 
  display: -webkit-flex; 
  display: flex; 
}

.timeline-header{
  color: #8f8f8f;
}

#name-container p {
  font-size: 12px;
  color: white;
  text-align: center;
 
}

#name-container{
  padding-top: 2.5%;
  padding-bottom: 5%;
  text-align: center;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#name {
  margin: 0px;
  margin-top: 24px;
  font-size: 36px;
  color: white;
  text-align: center;
}


@media print {
  /* styles here */
  /* set print margins to 0.5 inch */
  @page {
    margin: 0.1in;
    font-size: 10px;
  }

  .panel-one{
    width: 1.8in;
    max-width: auto;
  }
  
  .panel-content{
    width: 6.5in;
  }

  .feed {
    min-height: auto;
  }
}